function getSessionStorage(key) {
  let val = sessionStorage.getItem(key)
  try {
    return JSON.parse(val)
  } catch {
    return val
  }
}

function setSessionStorage(key, value) {
  if (typeof value !== 'string') {
    if (value === undefined) {
      value = null
    } else {
      value = JSON.stringify(value)
    }
  }
  sessionStorage.setItem(key, value)
}

export default {
  title: '开箱扫码，100%赢红包',
  // 加载远程图
  loadRemotePic: true,
  // 埋点配置
  tracking: {
    url: process.env.VUE_APP_BASE_LOG + '/osa/logAgent/save',
    scene: {
      //页面进入前
      before: {
        Home: {
          userVisit: 6
        }
        // 路由名称
        // Front: {
        //   userVisit: 38,
        //   back: 17
        // },
        // Middle: {
        //   userVisit: 6,
        //   back(from) {
        //     let backValue = ''
        //     // 活动已过期
        //     if (getSessionStorage('pseudoMode')) {
        //       backValue = 7
        //     } else if (!getSessionStorage('isFirstTime')) {
        //       // 前置转盘已抽奖后第二次进入
        //       backValue = 8
        //     } else if (from.name == 'Front' || getSessionStorage('isFrontPageEnter')) {
        //       // 从前置跳转红包页
        //       setSessionStorage('isFrontPageEnter', true)
        //       backValue = 1
        //     }
        //     setSessionStorage('backValue', backValue)
        //     return backValue
        //   }
        // },
        // Back: {
        //   userVisit: 42,
        //   back() {
        //     let backValue = 'null'
        //     if (getSessionStorage('isEnd')) {
        //       //活动已结束或前置转盘已抽奖后第二次进入
        //       backValue = 'null'
        //     } else if (getSessionStorage('isMiddlePageDraw')) {
        //       //中红包抽电子奖back转盘
        //       backValue = 5
        //     } else if (getSessionStorage('isMiddlePageEnter')) {
        //       //中红包back
        //       backValue = 3
        //     } else if (
        //       getSessionStorage('_isBack') == 'coupons' ||
        //       getSessionStorage('isDrawFrontCoupons')
        //     ) {
        //       //未中奖back转盘
        //       backValue = 11
        //     }
        //     setSessionStorage('backValue', backValue)
        //     return backValue
        //   }
        // }
      },
      //页面进入后
      after: {
        Home: {
          userVisit: 2
        }
      },
      //返回页面
      back: {},
      //页面所有接口请求后埋点
      nextRequest: {
        // Front: {
        //   userVisit: 39,
        //   back: 17
        // },
        // Middle: {
        //   userVisit: 2,
        //   back() {
        //     return getSessionStorage('backValue') || 'null'
        //   }
        // },
        // Back: {
        //   userVisit: 2,
        //   back() {
        //     return getSessionStorage('backValue') || 'null'
        //   }
        // }
      }
    },
    // 转换页面埋点
    transform(routeName) {
      // 活动已结束
      if (getSessionStorage('isTrackingBack')) {
        if (routeName == 'Back') {
          routeName = 'Middle'
        }
      }
      return routeName
    },
    hook(type, obj) {
      let typeMapping = {
        // 来自v-tracking
        click() {
          console.log('click::', obj.routeName)
          if (obj.routeName == 'Back') {
            // 如果是抽奖按钮类名
            if (obj.el.className == 'start-btn' && !getSessionStorage('isEnd')) {
              setSessionStorage('isEnd', true)
            }
          }
        }
      }
      typeMapping[type] && typeMapping[type]()
    }
  }
}
